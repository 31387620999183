import api from "../../../api";

export default {
  //Reset State
  resetDspState({ commit }) {
    commit("resetDspState");
  },

  getKenyaDiscoveryData({ commit }) {
    return api.dsp.getKenyaDiscoveryData().then((response) => {
      const payload = {};
      payload.kenya_discovery_data = response;
      commit("setKenyaDiscoveryData", payload);
    });
  },

  getCountryDiscoveryData({ commit }, country) {
    return api.dsp.getCountryForecast(country).then((response) => {
      const payload = {};
      payload[country + "_discovery_data"] = response;
      const mutation =
        "set" +
        country.charAt(0).toUpperCase() +
        country.slice(1) +
        "DiscoveryData";
      commit(mutation, payload);
    });
  },

  getCountrySitesData({ commit }, req_body) {
    return api.dsp.getDiscoveryPublishers(req_body).then((response) => {
      const payload = {};
      const { country } = req_body;
      payload[country + "_sitesAndApps"] = response.discovery_data;
      const mutation =
        "set" +
        country.charAt(0).toUpperCase() +
        country.slice(1) +
        "SitesAndApps";
      commit(mutation, payload);
    });
  },

  getAppCountryDiscoveryData({ commit }, req_body) {
    return api.dsp.getAppsDiscoveryForecast(req_body).then((response) => {
      const payload = {};
      const { app, country } = req_body;
      payload[app + "_" + country + "_discovery_data"] = response;
      const mutation =
        "set" +
        app +
        country.charAt(0).toUpperCase() +
        country.slice(1) +
        "DiscoveryData";
      commit(mutation, payload);
    });
  },

  getAppCountrySitesData({ commit }, req_body) {
    return api.dsp.getAppPublishers(req_body).then((response) => {
      const payload = {};
      const { country, app } = req_body;
      payload[app + "_" + country + "_sitesAndApps"] = response.discovery_data;
      const mutation = "set" + app + "_" + country + "_" + "SitesAndApps";
      commit(mutation, payload);
    });
  },

  getStaticDspData({ commit }) {
    return api.dsp.getStaticDspData().then((response) => {
      const payload = {};
      payload.static_dsp_data = response;
      commit("setStaticDspData", payload);
    });
  },

  getOperaKenyaForecast({ commit }) {
    return api.dsp.getOperaKenyaForecast().then((response) => {
      const payload = {};
      payload.opera_kenya_forecast = response;
      commit("setOperaKenyaForecast", payload);
    });
  },

  getOperaSitesAndApps({ commit }, page) {
    return api.dsp.getOperaSitesAndApps(page).then((response) => {
      const payload = {};
      payload.opera_sitesAndApps = response.discovery_data;
      commit("setOperaSitesAndApps", payload);
    });
  },

  getTruecallerKenyaForecast({ commit }) {
    return api.dsp.getTruecallerKenyaForecast().then((response) => {
      const payload = {};
      payload.truecaller_kenya_forecast = response;
      commit("setTruecallerKenyaForecast", payload);
    });
  },

  getTruecallerSitesAndApps({ commit }, page) {
    return api.dsp.getTruecallerSitesAndApps(page).then((response) => {
      const payload = {};
      payload.truecaller_sitesAndApps = response.discovery_data;
      commit("setTruecallerSitesAndApps", payload);
    });
  },

  getTranssionKenyaForecast({ commit }) {
    return api.dsp.getTranssionKenyaForecast().then((response) => {
      const payload = {};
      payload.transsion_kenya_forecast = response;
      commit("setTranssionKenyaForecast", payload);
    });
  },

  getTranssionSitesAndApps({ commit }, page) {
    return api.dsp.getTranssionSitesAndApps(page).then((response) => {
      const payload = {};
      payload.transsion_sitesAndApps = response.discovery_data;
      commit("setTranssionSitesAndApps", payload);
    });
  },

  getPhoenixKenyaForecast({ commit }) {
    return api.dsp.getPhoenixKenyaForecast().then((response) => {
      const payload = {};
      payload.phoenix_kenya_forecast = response;
      commit("setPhoenixKenyaForecast", payload);
    });
  },

  getPhoenixSitesAndApps({ commit }, page) {
    return api.dsp.getPhoenixSitesAndApps(page).then((response) => {
      const payload = {};
      payload.phoenix_sitesAndApps = response.discovery_data;
      commit("setPhoenixSitesAndApps", payload);
    });
  },

  getSitesAndApps({ commit }, page) {
    return api.dsp.getKenyaSitesAndApps(page).then((response) => {
      const payload = {};
      payload.sitesAndApps = response.discovery_data;
      commit("setSitesAndApps", payload);
    });
  },

  getInternationalDiscoveryData({ commit }) {
    return api.dsp.getInternationalDiscoveryData().then((response) => {
      const payload = {};
      payload.international_discovery_data = response.data.discovery_data;
      commit("setInternationalDiscoveryData", payload);
    });
  },

  getGoogleUserLists({ commit }) {
    return api.dsp.getUserLists().then((response) => {
      const payload = {};
      payload.user_lists = response;
      commit("setGoogleUserLists", payload);
    });
  },

  getRemarketingTag({ commit }) {
    return api.dsp.getRemarketingTag().then((response) => {
      const payload = {};
      payload.remarketing_tag = response;
      commit("setRemarketingTag", payload);
    });
  },

  createUserList({ commit }, body) {
    return api.dsp.createUserList(body).then((response) => {
      const payload = {};
      payload.user_list = response;
      commit("setNewUserList", payload);
    });
  },

  getSingleUserList({ commit }, id) {
    return api.dsp.getSingleUserList(id).then((response) => {
      const payload = {};
      payload.single_user_list = response;
      commit("setSingleUserList", payload);
    });
  },

  updateUserList({ commit }, req_body) {
    return api.dsp.updateUserList(req_body).then((response) => {
      const payload = {};
      payload.updated_user_list = response;
      commit("setUpdatedUserList", payload);
    });
  },

  openUserList({ commit }, id) {
    return api.dsp.openUserList(id).then((response) => {
      const payload = {};
      payload.open_user_list = response;
      commit("setOpenUserList", payload);
    });
  },

  closeUserList({ commit }, id) {
    return api.dsp.closeUserList(id).then((response) => {
      const payload = {};
      payload.close_user_list = response;
      commit("setCloseUserList", payload);
    });
  },
  getGoogleCreatives({ commit }, page_size) {
    return api.dsp.getCreatives(page_size).then((response) => {
      const payload = {};
      payload.google_creatives = response;
      commit("setGoogleCreatives", payload);
    });
  },

  //Get All campaigns
  getAllDspCampaigns({ commit }) {
    return api.dsp.getCampaigns().then((response) => {
      const payload = {};
      payload.dsp_campaigns = response;
      commit("setDspCampaigns", payload);
    });
  },

  //Get All campaigns
  getDropdownCampaigns({ commit }, body) {
    return api.dsp.getDropdownCampaigns(body).then((response) => {
      const payload = {};
      payload.dsp_dropdown_campaigns = response;
      commit("setDspDropdownCampaigns", payload);
    });
  },

  //Get All campaigns
  getDraftDspCampaigns({ commit }, body) {
    return api.dsp.getDraftCampaigns(body).then((response) => {
      const payload = {};
      payload.dsp_campaigns = response;
      commit("setDspCampaigns", payload);
    });
  },

  //Get campaign stats
  getCampaignStats({ commit }, body) {
    return api.dsp.getCampaignsStatistics(body).then((response) => {
      const payload = {};
      payload.dsp_campaignStat = response;
      commit("setDspCampaignStat", payload);
    });
  },

  //Get app campaigns (OPERA/TRANSSION/TRUECALLER/PHOENIX|CTV)
  getAppDspCampaigns({ commit }, body) {
    return api.dsp.getAppCampaigns(body).then((response) => {
      const payload = {};
      payload.dsp_campaigns = response;
      commit("setDspCampaigns", payload);
    });
  },

  //Get paginated Creatives
  getPaginatedCreatives({ commit }, body) {
    return api.dsp.readPaginatedCreatives(body).then((response) => {
      const payload = {};
      payload.dsp_creatives = response;
      commit("setDspCreatives", payload);
    });
  },

  updateBannerStates({ commit }, action) {
    return api.dsp.runCronJobs(action).then((response) => {
      const payload = {};
      payload.update_banners_status = response;
      commit("setBannerStatusesUpdate", payload);
    });
  },

  //get app campaigns creatives (opera/ transsion/truecaller/phoenix)
  getAppDspCreatives({ commit }, body) {
    return api.dsp.getAppCreatives(body).then((response) => {
      const payload = {};
      payload.dsp_creatives = response;
      commit("setDspCreatives", payload);
    });
  },
  //get app campaigns reporting (opera/ transsion/truecaller/phoenix)
  getAppCampaignDspReporting({ commit }, body) {
    return api.dsp.getAppCampaignReporting(body).then((response) => {
      const payload = {};
      payload.all_campaigns_reports = response;
      commit("setAllCampaignsReports", payload);
    });
  },

  //get publisher profiles
  getDspPublisherProfiles({ commit }) {
    return api.dsp.getPublisherProfiles().then((response) => {
      const payload = {};
      payload.publisher_profiles = response;
      commit("setpublisherProfiles", payload);
    });
  },

  //Submit campaign banners
  submitCampaignBanners({ commit }, campaignID) {
    return api.dsp.submitCampaignBanners(campaignID).then((response) => {
      const payload = {};
      payload.dsp_campaigns = response;
      commit("setDspCampaigns", payload);
    });
  },

  //Get Single campaign - Expects Id
  getOneDspCampaign({ commit }, id) {
    return api.dsp.getOneCampaign(id).then((response) => {
      const payload = {};

      payload.dsp_campaign = response;

      commit("setDspCampaign", payload);
    });
  },

  //Delete Campaign - Expects ID
  deleteCampaign({ commit }, id) {
    return api.dsp.deleteCampaign(id).then((response) => {
      const payload = {};
      payload.dsp_campaign = response;
      commit("setDspCampaign", payload);
    });
  },

  //Get paginated app campaign Creatives
  getAppCampaignCreatives({ commit }, body) {
    return api.dsp.readAppCampaignCreatives(body).then((response) => {
      const payload = {};
      payload.dsp_creatives = response;
      commit("setDspCreatives", payload);
    });
  },

  //Get single creative/banner
  getSingleCreative({ commit }, id) {
    return api.dsp.getSingleCreative(id).then((response) => {
      const payload = {};
      payload.dsp_creative = response;
      commit("setDspCreative", payload);
    });
  },

  //Get unsubmitted creatives/banner
  getUnsubCreative({ commit }, page) {
    return api.dsp.getUnsubmittedCreatives(page).then((response) => {
      const payload = {};
      payload.unsubmitted_creatives = response;
      commit("setUnsubmittedCreatives", payload);
    });
  },

  //Get disapproved creatives/Banner
  getDisapCreative({ commit }, page) {
    return api.dsp.getDisapprovedCreatives(page).then((response) => {
      const payload = {};
      payload.disapproved_creatives = response;
      commit("setDisapprovedCreatives", payload);
    });
  },

  //Delete Creative - Expects ID
  deleteCreative({ commit }, id) {
    return api.dsp.deleteCreative(id).then((response) => {
      const payload = {};
      payload.dsp_creative = response;
      commit("setDspCreative", payload);
    });
  },

  //Submit Banner/Creative
  submitBanner({ commit }, id) {
    return api.dsp.submitBanner(id).then((response) => {
      const payload = {};
      payload.dsp_creative = response;

      commit("setDspCreative", payload);
    });
  },

  //Pause Banner/Creative
  pauseBanner({ commit }, id) {
    return api.dsp.pauseBanner(id).then((response) => {
      const payload = {};
      payload.dsp_creative = response;

      commit("setDspCreative", payload);
    });
  },

  //Make campaign runnable/offline
  manageCampaignBiddingStatus({ commit }, id) {
    return api.dsp.manageCampaignBiddingStatus(id).then((response) => {
      const payload = {};
      payload.dsp_campaign = response;

      commit("setDspCampaign", payload);
    });
  },

  //submit multiple unsubmitted creatives
  submitUnsubBanner({ commit }, body) {
    return api.dsp.submitUnsubmittedBanners(body).then((response) => {
      const payload = {};
      payload.dsp_creatives = response;
      commit("setDspCreatives", payload);
    });
  },

  //Submit multiple disapproved creatives
  submitManyBanners({ commit }, body) {
    return api.dsp.submitManyCampaignBanners(body).then((response) => {
      const payload = {};
      payload.dsp_creative = response;

      commit("setDspCreative", payload);
    });
  },

  //** Pause multiple banners */
  pauseManyBanners({ commit }, body) {
    return api.dsp.pauseManyCampaignBanners(body).then((response) => {
      const payload = {};
      payload.dsp_creative = response;

      commit("setDspCreative", payload);
    });
  },
  //Resubmit Banner/Creative
  resubmitBanner({ commit }, id) {
    return api.dsp.resubmitBanner(id).then((response) => {
      const payload = {};
      payload.dsp_creative = response;

      commit("setDspCreative", payload);
    });
  },

  //get all advertisers
  getAllAdvertisers({ commit }) {
    return api.dsp.getAdvertisers().then((response) => {
      const payload = {};
      payload.dsp_advertisers = response;

      commit("setDSPAdvertisers", payload);
    });
  },

  //get campaign rules/rtb standards
  getAllCampaignRules({ commit }) {
    return api.dsp.getCampaignRules().then((response) => {
      const payload = {};
      payload.dsp_campaign_rules = response;
      commit("setCampaignRules", payload);
    });
  },

  //Get target rule
  getAllTargets({ commit }) {
    return api.dsp.getTargets().then((response) => {
      const payload = {};
      payload.dsp_targets = response.data;
      commit("setTargets", payload);
    });
  },

  //Get app campaign target
  getAllAppCampaignTargets({ commit }, app) {
    return api.dsp.getAppCampaignTargets(app).then((response) => {
      const payload = {};
      payload.dsp_targets = response;
      commit("setTargets", payload);
    });
  },

  //Get all Google Pretargets
  getGooglePreTargets({ commit }) {
    return api.dsp.setGooglePretargetingConfigs().then((response) => {
      const payload = {};

      payload.google_pretargets = response;

      commit("setGooglePretargets", payload);
    });
  },

  createPretarget({ commit }, data) {
    return api.dsp.createGooglePretarget(data).then((response) => {
      const payload = {};
      payload.new_pretarget = response;
      commit("setNewGooglePretarget", payload);
    });
  },

  updatePretarget({ commit }, req_body) {
    return api.dsp.updateGooglePretarget(req_body).then((response) => {
      const payload = {};
      payload.update_pretarget = response;
      commit("setUpdatePretarget", payload);
    });
  },

  suspendPretarget({ commit }, id) {
    return api.dsp.suspendGooglePretarget(id).then((response) => {
      const payload = {};
      payload.suspended_pretarget = response;
      commit("setSuspendedPretarget", payload);
    });
  },

  activatePretarget({ commit }, id) {
    return api.dsp.activateGooglePretarget(id).then((response) => {
      const payload = {};
      payload.activate_pretarget = response;
      commit("setActivatePretarget", payload);
    });
  },

  deletePretarget({ commit }, id) {
    return api.dsp.deleteGooglePretarget(id).then((response) => {
      const payload = {};
      payload.delete_pretarget = response;
      commit("setDeletePretarget", payload);
    });
  },

  newTargetedApps({ commit }, req_body) {
    return api.dsp.addTargetedApps(req_body).then((response) => {
      const payload = {};
      payload.targeted_apps = response;
      commit("setTargetedApps", payload);
    });
  },

  removeTargetedApps({ commit }, req_body) {
    return api.dsp.removeTargetedApps(req_body).then((response) => {
      const payload = {};
      payload.remove_apps = response;
      commit("setRemoveTargetedApp", payload);
    });
  },

  addTargetedPublishers({ commit }, req_body) {
    return api.dsp.addTargetedPublishers(req_body).then((response) => {
      const payload = {};
      payload.add_publishers = response;
      commit("setNewTargetedPublishers", payload);
    });
  },

  removeTargetedPublishers({ commit }, req_body) {
    return api.dsp.removeTargetedPublishers(req_body).then((response) => {
      const payload = {};
      payload.remove_publishers = response;
      commit("setRemoveTargetedPublishers", payload);
    });
  },

  addTargetedSites({ commit }, req_body) {
    return api.dsp.addTargetedSites(req_body).then((response) => {
      const payload = {};
      payload.add_sites = response;
      commit("setTargetedSites", payload);
    });
  },

  removeTargetedSites({ commit }, req_body) {
    return api.dsp.removeTargetedSites(req_body).then((response) => {
      const payload = {};
      payload.remove_sites = response;
      commit("setremoveTargetedSites", payload);
    });
  },
  contentCategoryLabels({ commit }) {
    return api.dsp.getContentLabels().then((response) => {
      const payload = {};
      payload.content_labels = response;
      commit("setContentLabels", payload);
    });
  },

  mobileOperatingSystemIds({ commit }) {
    return api.dsp.getMobileOS().then((response) => {
      const payload = {};
      payload.mobile_os = response;
      commit("setMobileOS", payload);
    });
  },

  getAllLanguages({ commit }) {
    return api.dsp.getLanguages().then((response) => {
      const payload = {};
      payload.languages = response;
      commit("setLanguages", payload);
    });
  },

  getGeoLocations({ commit }, location_params) {
    return api.dsp.getGeoIds(location_params).then((response) => {
      const payload = {};
      payload.geo_locations = response;
      commit("setGeolocations", payload);
    });
  },

  includedLocationbyId({ commit }, params) {
    return api.dsp.getIncludedGeolocationsbyId(params).then((response) => {
      const payload = {};
      payload.included_geo_locations_by_id = response;
      commit("setIncludedGeolocationsbyId", payload);
    });
  },

  excludedLocationbyId({ commit }, params) {
    return api.dsp.getExcludedGeolocationsbyId(params).then((response) => {
      const payload = {};
      payload.excluded_geo_locations_by_id = response;
      commit("setExcludedGeolocationsbyId", payload);
    });
  },

  getVerticalTargeting({ commit }) {
    return api.dsp.getVerticals().then((response) => {
      const payload = {};
      payload.vertical_targeting = response;
      commit("setVerticalTargeting", payload);
    });
  },

  //Get all Agencies
  getAgencies({ commit }) {
    return api.dsp.getAgencies().then((response) => {
      const payload = {};
      payload.agencies = response.data;
      commit("setAgencies", payload);
    });
  },

  //create campaign
  createDspCampaigns({ commit }, data) {
    return api.dsp.createCampaign(data).then((response) => {
      const payload = {};
      payload.dsp_campaign = response;

      commit("setDspCampaign", payload);
    });
  },

  //Updated Campaign
  updateDspCampaign({ commit }, data) {
    return api.dsp.updateCampaign(data).then((response) => {
      const payload = {};

      payload.dsp_campaign = response;

      commit("setDspCampaign", payload);
    });
  },

  //create banner
  createDspBanner({ commit }, data) {
    return api.dsp.createBanner(data).then((response) => {
      const payload = {};
      payload.dsp_creative = response;
      commit("setDspCreative", payload);
    });
  },

  getPretargetingConfigs({ commit }, status) {
    return api.dsp.getGooglePretargets(status).then((response) => {
      const payload = {};
      payload.pretargeting_configs = response;
      commit("setGooglePretargetingConfigs", payload);
    });
  },
  getSinglePretarget({ commit }, id) {
    return api.dsp.getPretarget(id).then((response) => {
      const payload = {};
      payload.single_pretarget = response;
      commit("setSingleGooglePretarget", payload);
    });
  },
  //update banner/creative
  updateDspBanner({ commit }, data) {
    return api.dsp.updateCreative(data).then((response) => {
      const payload = {};
      payload.dsp_creative = response;
      commit("setDspCreative", payload);
    });
  },

  //create single target
  createTargetRule({ commit }, body) {
    return api.dsp.createTargetingRule(body).then((response) => {
      const payload = {};
      payload.targeting_rule = response;
      commit("setTargetingRule", payload);
    });
  },

  //create single app campaign target
  createAppCampaignTargetRule({ commit }, req_body) {
    return api.dsp.createAppCampaignTargetingRule(req_body).then((response) => {
      const payload = {};
      payload.targeting_rule = response;
      commit("setTargetingRule", payload);
    });
  },
  //update single target
  updateTargetRule({ commit }, req_body) {
    return api.dsp.UpdateTargetingRule(req_body).then((response) => {
      const payload = {};
      payload.targeting_rule = response;
      commit("setupdateTargetingRule", payload);
    });
  },

  //get single target rule
  singleTargetRule({ commit }, target_Id) {
    return api.dsp.singleTargetingRule(target_Id).then((response) => {
      const payload = {};
      payload.single_target = response;
      commit("setSingleTargetingRule", payload);
    });
  },
  // delete target rule
  deleteTargetRule({ commit }, target_Id) {
    return api.dsp.deleteTarget(target_Id).then((response) => {
      const payload = {};
      payload.deleted_target = response;
      commit("setDeletedTarget", payload);
    });
  },

  //rtb standards
  getStandards({ commit }) {
    return api.dsp.getrtbStandards().then((response) => {
      const payload = {};
      payload.rtb_standards = response;
      commit("setRtbStandards", payload);
    });
  },

  createRule({ commit }, body) {
    return api.dsp.creatertbStandard(body).then((response) => {
      const payload = {};
      payload.targeting_rule = response;
      commit("setCreateRtbRule", payload);
    });
  },
  updateRule({ commit }, req_body) {
    return api.dsp.updatertbStandard(req_body).then((response) => {
      const payload = {};
      payload.update_rtbRule = response;
      commit("setUpdateRule", payload);
    });
  },
  singleRule({ commit }, rtb_id) {
    return api.dsp.singlertbrule(rtb_id).then((response) => {
      const payload = {};
      payload.single_rtbRule = response;
      commit("setsinglertbRule", payload);
    });
  },
  deleteRule({ commit }, rtb_id) {
    return api.dsp.deletertbStandard(rtb_id).then((response) => {
      const payload = {};
      payload.deleted_rule = response;
      commit("setDeletedRule", payload);
    });
  },
  uploadDSPReport({ commit }, body) {
    return api.dsp.uploadDSPReportJson(body).then((response) => {
      const payload = {};
      payload.upload_dsp_report_json = response;
      commit("setUploadDSPReport", payload);
    });
  },
  uploadLeadGen({ commit }, body) {
    return api.dsp.uploadLeadGen(body).then((response) => {
      const payload = {};
      payload.upload_lead_gen_csv = response;
      commit("setUploadLeadGen", payload);
    });
  },
  uploadScreenshots({ commit }, body) {
    return api.dsp.uploadScreenshots(body).then((response) => {
      const payload = {};
      payload.dsp_campaign = response;
      commit("setCampaignScreenshot", payload);
    });
  },

  //get campaign screenshots
  getCampaignScreenshots({ commit }, id) {
    return api.dsp.getCampaignScreenshots(id).then((response) => {
      const payload = {};
      payload.campaign_screenshots = response;
      commit("setCampaignScreenshots", payload);
    });
  },

  //delete campaign screenshot
  deleteCampaignScreenshot({ commit }, body) {
    return api.dsp.deleteCampaignScreenshot(body).then((response) => {
      const payload = {};
      payload.campaign_screenshots = response;

      commit("setCampaignScreenshot", payload);
    });
  },

  postNote({ commit }, body) {
    return api.dsp.postNote(body).then((response) => {
      const payload = {};
      payload.dsp_campaign = response;
      commit("setEmptyResponse", payload);
    });
  },
  //Delete Note - Expects ID
  deleteNote({ commit }, id) {
    return api.dsp.deleteNote(id).then((response) => {
      const payload = {};
      payload.response = response;
      commit("setEmptyResponse", payload);
    });
  },
  //Delete Note - Expects ID
  getCampaignNotes({ commit }, id) {
    return api.dsp.getCampaignNotes(id).then((response) => {
      const payload = {};
      payload.campaign_notes = response;
      commit("setCampaignNotes", payload);
    });
  },
  getAllCampaignsStatistics({ commit }) {
    return api.dsp.getCampaignsStats().then((response) => {
      const payload = {};
      payload.all_campaigns_statistics = response;
      commit("setAllCampaignStatistics", payload);
    });
  },
  getHomeCampaignStats({ commit }) {
    return api.dsp.getHomeCampaignStats().then((response) => {
      const payload = {};
      payload.home_campaign_statistics = response;
      commit("setHomeCampaignStatistics", payload);
    });
  },
  getAllCampaignsReports({ commit }, body) {
    return api.dsp.getAllCampaignsReports(body).then((response) => {
      const payload = {};
      payload.all_campaigns_reports = response;
      commit("setAllCampaignsReports", payload);
    });
  },
  singleCampaignStatistics({ commit }, id) {
    return api.dsp.getSingleCampaignStatistics(id).then((response) => {
      const payload = {};
      payload.campaign_statistics = response;
      commit("setSingleCampaignStatistics", payload);
    });
  },

  //update campaign views
  updateCampaignViews({ commit }, body) {
    return api.dsp.updateCampaignViews(body).then((response) => {
      const payload = {};
      payload.campaign_statistics = response;
      commit("setSingleCampaignStatistics", payload);
    });
  },

  //update campaign app installs
  updateCampaignAppInstalls({ commit }, body) {
    return api.dsp.updateCampaignAppInstalls(body).then((response) => {
      const payload = {};
      payload.campaign_statistics = response;
      commit("setSingleCampaignStatistics", payload);
    });
  },

  //update campaign engagement
  updateCampaignEngagement({ commit }, body) {
    return api.dsp.updateCampaignEngagement(body).then((response) => {
      const payload = {};
      payload.campaign_statistics = response;
      commit("setSingleCampaignStatistics", payload);
    });
  },

  //update campaign status (Paused, Completed, Active, In Review)
  updateCampaignStatus({ commit }, body) {
    return api.dsp.updateCampaignStatus(body).then((response) => {
      if (response.status == "false") {
        commit("setValidationErrors", response.message);
      }
    });
  },

  refreshGoogleTargets({ commit }, body) {
    return api.dsp.refreshGoogleTargets(body).then((response) => {
      if (response.status == "false") {
        commit("setValidationErrors", response.message);
      }
    });
  },

  getDSPAppsStats({ commit }, req_body) {
    return api.dsp.getAppStatistics(req_body).then((response) => {
      const payload = {};
      payload.app_campaigns_statistics = response;
      commit("setAppCampaignStatistics", payload);
    });
  },

  //Get disapproved creatives for truecaller/transsion/Opera
  getAppsDisapprovedCreatives({ commit }) {
    return api.dsp.getTargetDisapprovedCreatives().then((response) => {
      const payload = {};
      payload.disapproved_creatives = response;
      commit("setDisapprovedCreatives", payload);
    });
  },

  //Get unsubmitted creatives for truecaller/transsion/opera
  getTargetUnsubmittedCreatives({ commit }, page) {
    return api.dsp.getTargetUnsubmittedCreatives(page).then((response) => {
      const payload = {};
      payload.unsubmitted_creatives = response;
      commit("setUnsubmittedCreatives", payload);
    });
  },
  //leads
  campaignLeads({ commit }, body) {
    return api.dsp.getCampaignLeads(body).then((response) => {
      const payload = {};
      payload.campaign_leads = response;
      commit("setCampaignLeads", payload);
    });
  },

  //leadgen campaigns
  LeadgenCampaigns({ commit }) {
    return api.dsp.getLeadgenCampaigns().then((response) => {
      const payload = {};
      payload.leads_campaigns = response;
      commit("setLeadsCampaigns", payload);
    });
  },

  //leads reports
  LeadsReports({ commit }, id) {
    return api.dsp.getReportLeads(id).then((response) => {
      const payload = {};
      payload.report_link = response;
      commit("setLeadsReport", payload);
    });
  },

  downloadCustomReport({ commit }, req_body) {
    return api.dsp.getCampaignCustomReport(req_body).then((response) => {
      const payload = {};
      payload.dsp_custom_report = response;
      commit("setDSPCustomReport", payload);
    });
  },

  getProgrammaticTodayInsights({ commit }) {
    return api.dsp.getTodayReports().then((response) => {
      const payload = {};
      payload.today_insights = response;
      commit("setTodayInsights", payload);
    });
  },

  //last stats update
  getLastStatsUpdate({ commit }) {
    return api.dsp.getLastStatsUpdate().then((response) => {
      const payload = {};
      payload.last_stats_update = response;
      commit("setLastStatsUpdate", payload);
    });
  },

  //Fetch Realtime campaign histogram data
  fetCampaignHistogramData({ commit }, filters) {
    return api.dsp.getHistogramData(filters).then((response) => {
      const payload = {};
      payload.histogram_data = response?.aggregations?.time_histogram?.buckets;
      commit("setHistogramData", payload);
    });
  },

  //Fetch active bidder campaigns
  getActiveBidderCampaigns({ commit }, filters) {
    return api.dsp.getActiveBidderCampaigns(filters).then((response) => {
      const payload = {};
      payload.active_bidder_campaigns = response?.aggregations?.field_values?.buckets;
      commit("setActiveBidderCampaigns", payload);
    });
  },

  //Get top publishers
  getTopPublishers({ commit }, filters) {
    return api.dsp.getTopPublishers(filters).then((response) => {
      const payload = {};
      payload.top_publishers = response?.aggregations?.time_histogram?.buckets;
      commit("setTopPublishers", payload);
    });
  },

  //Get to Carriers
  getTopCarriers({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.top_carriers = response?.aggregations?.test_histogram?.buckets;

      commit("setTopCarriers", payload);
    });
  },

  //Get citywise distribution
  getCityWiseDistribution({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.city_wise_distribution = response?.aggregations?.test_histogram?.buckets;

      commit("setCityDistribution", payload);
    });
  },

  //Get top apps
  getTopApps({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.top_apps = response?.aggregations?.test_histogram?.buckets;

      commit("setTopApps", payload);
    });
  },

  //OS Distribution
  getOperatingSystemDistribution({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.os_distribution = response?.aggregations?.test_histogram?.buckets;

      commit("setOSDistribution", payload);
    });
  },

  //Envitonment Distribution
  getEnvironmentDistribution({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.environment_distribution = response?.aggregations?.test_histogram?.buckets;

      commit("setEnvironmentDistribution", payload);
    });
  },

  //Device make distribution
  getDeviceMakeDistribution({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.device_make_distribution = response?.aggregations?.test_histogram?.buckets;

      commit("setDeviceMakeDistribution", payload);
    });
  },

  //Get device model distribution
  getDeviceModelDistribution({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.device_model_distribution = response?.aggregations?.test_histogram?.buckets;

      commit("setDeviceModelDistribution", payload);
    });
  },

  //Browser distribution
  getBrowserDistribution({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.browser_distribution = response?.aggregations?.test_histogram?.buckets;

      commit("setBrowserDistribution", payload);
    });
  },

  //Cpmmection  types
  getConnectionTypes({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.connection_types = response?.aggregations?.test_histogram?.buckets;

      commit("setConnectionTypes", payload);
    });
  },

  //Device  types
  getDeviceTypes({ commit }, body) {
    return api.dsp.getDynamicELKData(body).then((response) => {
      const payload = {};
      payload.device_types = response?.aggregations?.test_histogram?.buckets;

      commit("setDeviceTypes", payload);
    });
  },

  //Campaign reach
  getCampaignReach({ commit }, id) {
    return api.dsp.getCampaignReach(id).then((response) => {
      const payload = {};
      payload.campaign_reach = response?.aggregations?.time_histogram?.buckets;
      commit("setCampaignReach", payload);
    });
  },

};
