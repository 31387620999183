class Google {
  constructor(client) {
    this.client = client;
  }
  //Google Scrapper Data
  getStaticDspData() {
    return this.client.get(`dsp/discovery/static_data`);
  }

  getLastStatsUpdate() {
    return this.client.get(`dsp/reporting/last-stats-update`);
  }

  getCountryForecast(country) {
    return this.client.get(`dsp/discovery/forecast?country=${country}`);
  }

  getDiscoveryPublishers(req_body) {
    const { country, page } = req_body;
    return this.client.get(
      `dsp/discovery/publishers?country=${country}&page=${page}`
    );
  }

  getAppsDiscoveryForecast(req_body) {
    const { app, country } = req_body;
    return this.client.get(
      `dsp/discovery/forecast?app=${app}&country=${country}`
    );
  }

  getAppPublishers(req_body) {
    const { app, country, page } = req_body;
    return this.client.get(
      `dsp/discovery/publishers?app=${app}&country=${country}&page=${page}`
    );
  }

  getKenyaDiscoveryData() {
    return this.client.get(`dsp/discovery/kenya_forecast`);
  }

  getKenyaSitesAndApps(page) {
    return this.client.get(`dsp/discovery/kenya_publishers/${page}`);
  }

  getOperaKenyaForecast() {
    return this.client.get(`dsp/discovery/opera_kenya_forecast`);
  }

  getOperaSitesAndApps(page) {
    return this.client.get(`dsp/discovery/opera_kenya_publishers/${page}`);
  }

  getTruecallerKenyaForecast() {
    return this.client.get(`dsp/discovery/truecaller_kenya_forecast`);
  }

  getTruecallerSitesAndApps(page) {
    return this.client.get(`dsp/discovery/truecaller_kenya_publishers/${page}`);
  }

  getTranssionKenyaForecast() {
    return this.client.get(`dsp/discovery/transsion_kenya_forecast`);
  }

  getTranssionSitesAndApps(page) {
    return this.client.get(`dsp/discovery/transsion_kenya_publishers/${page}`);
  }

  getPhoenixKenyaForecast() {
    return this.client.get(`dsp/discovery/phoenix_kenya_forecast`);
  }

  getPhoenixSitesAndApps(page) {
    return this.client.get(`dsp/discovery/phoenix_kenya_publishers/${page}`);
  }

  //Google Pretargets
  getGooglePretargets(status) {
    const state = status ? status : "";
    return this.client.get(`dsp/pretargets?include_archived=${state}`);
  }

  //Refresh google pretargets
  refreshGoogleTargets() {
    let page_size = 1000;
    return this.client.get(`dsp/pretargets/store/${page_size}`);
  }

  getPretarget(pretarget_id) {
    return this.client.get(`dsp/pretargets/${pretarget_id}`);
  }

  //Creatives
  getCreatives(page_size) {
    return this.client.get(`dsp/creatives/${page_size}`);
  }

  runCronJobs(action) {
    return this.client.post(`crons`, action);
  }

  //All campaigns
  getCampaigns() {
    return this.client.get(`dsp/campaigns`);
  }

  getDraftCampaigns({ page, params }) {
    const per_page = params.per_page;
    return this.client.get(
      `dsp/campaigns/draft?page=${page}&per_page=${per_page}`
    );
  }

  //app campaigns (opera/transsion/truecaller/phoenix/ctv)
  getAppCampaigns({ page, search, client, params, app, status_filter }) {
    const per_page = params.per_page;
    const filter = status_filter ? status_filter : "";
    return this.client.get(
      `dsp/appCampaigns/${app}/campaigns?page=${page}&per_page=${per_page}&status_filter=${filter}&search=${search}&client=${client}`
    );
  }
  //app campaign creatives (opera/transsion/truecaller/phoenix)
  readAppCampaignCreatives({ page, params, app }) {
    const per_page = params.per_page;
    return this.client.get(
      `dsp/appCampaigns/${app}/creatives?page=${page}&per_page=${per_page}`
    );
  }
  //app campaign reporting (opera/transsion/truecaller)
  getAppCampaignReporting({ page, params, app }) {
    const per_page = params.per_page;
    return this.client.get(
      `dsp/appCampaigns/${app}/reports?page=${page}&per_page=${per_page}`
    );
  }

  //Get One campaign
  getOneCampaign(campaignId) {
    return this.client.get(`dsp/campaigns/${campaignId}`);
  }

  //Get Dropdown campaign
  getDropdownCampaigns() {
    return this.client.get(`dsp/campaigns/dropdown`);
  }

  //Submit campaign banners
  submitCampaignBanners(campaignId) {
    return this.client.post(`dsp/campaigns/${campaignId}/submit_banners`);
  }

  //Delete One campaign
  deleteCampaign(campaignId) {
    return this.client.delete(`dsp/campaigns/${campaignId}`);
  }

  //Get paginated creatives
  readPaginatedCreatives({ page, params, search, channel, campaign_id }) {
    const per_page = params.per_page;
    campaign_id = campaign_id ? campaign_id : "";
    return this.client.get(`dsp/banners?channel=${channel}&page=${page}&per_page=${per_page}&search=${search}&campaign_id=${campaign_id}`);
  }

  //update campaign views
  updateCampaignViews(body) {
    return this.client.post(`dsp/reporting/video`, body);
  }

  //update campaign views
  updateCampaignEngagement(body) {
    return this.client.post(`dsp/reporting/engagements`, body);
  }

  //update campaign app installs
  updateCampaignAppInstalls(body) {
    return this.client.post(`dsp/reporting/installs`, body);
  }

  //upload screenshot
  uploadScreenshots({ campaignID, body }) {
    return this.client.post(
      `dsp/campaigns/${campaignID}/upload_campaign_screenshot`,
      body
    );
  }
  //upload screenshot
  getCampaignScreenshots(campaignID) {
    return this.client.get(
      `dsp/campaigns/${campaignID}/get_campaign_screenshot`
    );
  }

  //delete campaign screenshot
  deleteCampaignScreenshot({ campaignID, path }) {
    return this.client.delete(
      `dsp/campaigns/${campaignID}/delete_campaign_screenshot?path=${path}`,
    );
  }

  postNote(body) {
    return this.client.post(`dsp/campaigns/notes`, body);
  }
  //Delete Note
  deleteNote(noteId) {
    return this.client.delete(`dsp/campaigns/notes/${noteId}`);
  }
  //get Note
  getCampaignNotes(campaign_id) {
    return this.client.get(`dsp/campaigns/notes/${campaign_id}`);
  }
  //update campaign status
  updateCampaignStatus({ campaign_id, req_body }) {
    return this.client.post(`dsp/campaigns/${campaign_id}/status`, req_body);
  }
  //get unsubmitted creatives
  getUnsubmittedCreatives(page) {
    return this.client.get(`dsp/banners/unsub?page=${page}`);
  }

  //get disapproved creatives
  getDisapprovedCreatives(page) {
    return this.client.get(`dsp/banners/disapproved?page=${page}`);
  }

  //Get One Banner/Creative
  getSingleCreative(creativeId) {
    return this.client.get(`dsp/banners/${creativeId}`);
  }

  //Delete One Creative
  deleteCreative(creativeId) {
    return this.client.delete(`dsp/banners/${creativeId}`);
  }

  //submit multiple unsubmitted creatives
  submitUnsubmittedBanners({ banner_ids }) {
    return this.client.post(`dsp/banners/submit`, banner_ids);
  }

  //re_submit multiple disapproved creatives
  submitManyCampaignBanners(body) {
    return this.client.post(`dsp/banners/re_submit`, body);
  }

  //pause multiple banners
  pauseManyCampaignBanners(body) {
    return this.client.put(`dsp/pause/banners`, body);
  }
  //Get all clients
  getAdvertisers() {
    return this.client.get(`clients`);
  }

  //Get campaign rules
  getCampaignRules() {
    return this.client.get(`dsp/rules/rtbstandards`);
  }

  //Get Google pretargets
  getGooglePreTargets() {
    return this.client.get(`dsp/pretargets`);
  }

  createGooglePretarget(body) {
    return this.client.post(`dsp/pretargets/create`, body);
  }

  updateGooglePretarget({ pretarget_id, body }) {
    return this.client.put(`dsp/pretargets/${pretarget_id}/update`, body);
  }

  suspendGooglePretarget(pretarget_id) {
    return this.client.get(`dsp/pretargets/${pretarget_id}/suspend`);
  }

  activateGooglePretarget(pretarget_id) {
    return this.client.get(`dsp/pretargets/${pretarget_id}/activate`);
  }
  deleteGooglePretarget(pretarget_id) {
    return this.client.delete(`dsp/pretargets/${pretarget_id}/delete`);
  }

  addTargetedApps({ pretarget_id, body }) {
    return this.client.post(
      `dsp/pretargets/${pretarget_id}/targeted_apps/add`,
      body
    );
  }

  removeTargetedApps({ pretarget_id, body }) {
    return this.client.post(
      `dsp/pretargets/${pretarget_id}/targeted_apps/remove`,
      body
    );
  }

  addTargetedPublishers({ pretarget_id, body }) {
    return this.client.post(
      `dsp/pretargets/${pretarget_id}/targeted_publishers/add`,
      body
    );
  }
  removeTargetedPublishers({ pretarget_id, body }) {
    return this.client.post(
      `dsp/pretargets/${pretarget_id}/targeted_publishers/remove`,
      body
    );
  }
  addTargetedSites({ pretarget_id, body }) {
    return this.client.post(
      `dsp/pretargets/${pretarget_id}/targeted_sites/add`,
      body
    );
  }
  removeTargetedSites({ pretarget_id, body }) {
    return this.client.post(
      `dsp/pretargets/${pretarget_id}/targeted_sites/remove`,
      body
    );
  }
  getContentLabels() {
    return this.client.get(`dsp/pretargets/contentlabels`);
  }
  getGeoIds(params) {
    return this.client.post(`dsp/pretargets/geoIDs`, params);
  }
  getIncludedGeolocationsbyId(body) {
    return this.client.post(`dsp/pretargets/geos`, body);
  }

  getExcludedGeolocationsbyId(body) {
    return this.client.post(`dsp/pretargets/geos`, body);
  }

  getMobileOS() {
    return this.client.get(`dsp/pretargets/mobileOS`);
  }

  getLanguages() {
    return this.client.get(`dsp/pretargets/languages`);
  }

  getVerticals() {
    return this.client.get(`dsp/pretargets/publishers`);
  }

  //Create DSP campaign
  createCampaign(body) {
    return this.client.post(`dsp/campaigns`, body);
  }

  //update DSP campaign
  updateCampaign({ campaign_id, req_body }) {
    return this.client.post(`dsp/campaigns/${campaign_id}`, req_body);
  }

  //Update DSP Creative/Banner
  updateCreative({ banner_body, banner_id }) {
    return this.client.post(`dsp/banners/${banner_id}`, banner_body);
  }

  //Create Banner/Creative
  createBanner(banner_body) {
    return this.client.post(`dsp/campaigns/create/banner`, banner_body);
  }

  //Submit Banner
  submitBanner(id) {
    return this.client.post(`dsp/banners/${id}/submit`);
  }

  //Pause Banner
  pauseBanner(id) {
    return this.client.get(`dsp/banners/${id}/pause`);
  }

  manageCampaignBiddingStatus(id) {
    return this.client.get(`dsp/campaigns/${id}/mark_as_runnable`);
  }
  //Resubmit Banner
  resubmitBanner(id) {
    return this.client.post(`dsp/banners/${id}/re_submit`);
  }

  //Get agencies
  getAgencies() {
    return this.client.get(`agencies`);
  }
  //Targeting rules
  getTargets() {
    const per_page = 1000;
    return this.client.get(`dsp/targets/list?per_page=${per_page}`);
  }
  //Targeting rules
  getAppCampaignTargets(app) {
    return this.client.get(`dsp/appCampaigns/${app}/targets/list`);
  }

  createAppCampaignTargetingRule({ app, body }) {
    return this.client.post(`dsp/appCampaigns/${app}/targets/create`, body);
  }
  createTargetingRule(body) {
    return this.client.post(`dsp/targets/create`, body);
  }
  singleTargetingRule(target_Id) {
    return this.client.get(`dsp/targets/${target_Id}/get`);
  }
  UpdateTargetingRule({ target_Id, body }) {
    return this.client.put(`dsp/targets/${target_Id}`, body);
  }
  deleteTarget(id) {
    return this.client.delete(`dsp/targets/${id}`);
  }

  //Rules
  getrtbStandards() {
    return this.client.get(`dsp/rules/rtbstandards`);
  }
  creatertbStandard(body) {
    return this.client.post(`dsp/rules/rtbstandards`, body);
  }
  updatertbStandard({ rtb_id, body }) {
    return this.client.put(`dsp/rules/rtbstandards/${rtb_id}`, body);
  }
  singlertbrule(rtb_id) {
    return this.client.get(`dsp/rules/rtbstandards/${rtb_id}`);
  }
  deletertbStandard(id) {
    return this.client.delete(`dsp/rules/rtbstandards/${id}`);
  }

  //User Lists
  getUserLists() {
    return this.client.get(`dsp/user_lists_and_remarketing/list`);
  }

  //RemarketingTag
  getRemarketingTag() {
    return this.client.get(`dsp/user_lists_and_remarketing/remarketingtag/get`);
  }
  createUserList(body) {
    return this.client.post(`dsp/user_lists_and_remarketing/create`, body);
  }

  updateUserList({ user_list_id, body }) {
    return this.client.put(
      `dsp/user_lists_and_remarketing/${user_list_id}/update`,
      body
    );
  }

  getSingleUserList(id) {
    return this.client.get(`dsp/user_lists_and_remarketing/${id}`);
  }

  openUserList(id) {
    return this.client.post(`dsp/user_lists_and_remarketing/${id}/open`);
  }

  closeUserList(id) {
    return this.client.post(`dsp/user_lists_and_remarketing/${id}/close`);
  }

  uploadDSPReportJson(body) {
    return this.client.post(`dsp/reporting/uploadcsvstats`, body);
  }

  uploadLeadGen({ body, campaign_id }) {
    return this.client.post(
      `dsp/campaigns/${campaign_id}/upload_leadgen_reports`,
      body
    );
  }

  getCampaignsStatistics({ page, params }) {
    const per_page = params.per_page;
    return this.client.get(
      `dsp/reporting/campaigns?page=${page}&per_page=${per_page}`
    );
  }

  getSingleCampaignStatistics(id) {
    return this.client.get(`dsp/reporting/campaigns/${id}`);
  }

  //landing page stats
  getHomeCampaignStats() {
    return this.client.get(`dsp/campaigns/count`);
  }
  getAllCampaignsReports(body) {
    return this.client.put(`dsp/reporting/campaignsreport`, body);
  }

  //getTargetAppCampaignStatistics
  getAppStatistics({ app, body }) {
    return this.client.put(`dsp/reporting/targeted_app_reports/${app}`, body);
  }

  //get Disapproved Creatives for target apps
  getTargetDisapprovedCreatives({ app }) {
    return this.client.get(`dsp/banners/disapproved/${app}`);
  }

  //Get Unsubmitted creatives for target apps
  getTargetUnsubmittedCreatives(app) {
    return this.client.get(`dsp/appCampaigns/${app}/unsubmitted_creatives`);
  }

  //get publisher profiles
  getPublisherProfiles() {
    return this.client.get(`dsp/publishers/profiles`);
  }

  getTodayReports() {
    return this.client.get(`dsp/reporting/dsp_cards_insights`);
  }

  //Leadgen leads
  getCampaignLeads({ campaign_id, page }) {
    return this.client.get(`dsp/reporting/leadgen/${campaign_id}?page=${page}`);
  }

  //leadgen campaigns
  getLeadgenCampaigns() {
    return this.client.get(`dsp/reporting/leadgen/campaigns`);
  }

  getReportLeads(campaignId) {
    return this.client.get(`dsp/reporting/leadgen/export/${campaignId}`);
  }

  getCampaignCustomReport({ campaign_id, start_date, end_date, format }) {
    return this.client.get(`dsp/reporting/export/${campaign_id}?start_date=${start_date}&end_date=${end_date}&format=${format}`);
  }

  //Get campaign histogramdata
  getHistogramData(){
    return this.client.get(`dsp/insights/campaigns-histogram-data`);
  }

  //Active Bidder Campaigns
  getActiveBidderCampaigns(){
    return this.client.get(`dsp/insights/active_bidder_campaigns`);
  }

  //Top Publishers
  getTopPublishers(){
    return this.client.get(`dsp/insights/top-publishers`);
  }

  //Dynamic ELK Data
  getDynamicELKData(body){
    return this.client.post(`dsp/insights/dynamic`, body);
  }

  //get camaign reach
  getCampaignReach(campaignID){
    return this.client.get(`dsp/insights/campaign-reach/${campaignID}`);
  }

}
export default Google;
